@import '../../constants/colors.scss';

.container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/images/houseOnRiver.jpg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

.signInContainer {
  width: 400px;
  height: 350px;
  max-width: 90%;
  // background-color: $darkgrey2;
  box-shadow: 0 0 25px -8px $black;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $darkgrey2;
    opacity: 0.5;
    backdrop-filter: blur(10px);
    z-index: -1;
  }

  .signInForm {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .formHeading {
      color: $white;
      text-transform: capitalize;
    }

    .inputContainer {
      margin-top: 30px;
      label {
        .emailInput {
          margin-bottom: 15px;
        }

        input {
          height: 50px;
          padding: 10px;
          border: 0;
          box-shadow: 0 0 2px $darkgrey;
          border-radius: 5px;
          width: 100%;
          outline-color: $logo_bg;
        }
      }
    }

    .submitButton {
      margin-top: 30px;
      padding: 10px;
      border: 1px solid $transparent;
      border-radius: 2px;
      background-color: $logo_bg;
      color: $white;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      cursor: pointer;
      transition: 0.2s all ease;
      outline: none;

      &:hover {
        border: 1px solid $logo_bg;
        background-color: $white;
        color: $logo_bg;
      }
    }
  }
  .error {
    color: $danger;
    text-align: left;
    margin: 0;
    margin-top: 10px;
  }
}
