$transparent: transparent;
$white: #fff;
$black: #000000;
$black_with_opacity: #000000CC;
$darkgrey: darkgrey;
$darkgrey2: #191919;
$darkgrey3: #969696;
$darkgrey2_with_opacity: #19191940;
$main_bg: #f4f4f4;
$body_copy_primary: #2b2b2b;
$body_copy_secondary: #ededed;
$body_copy_tertiary: #ebebeb;
$footer_bg: #2b2b2b;
$arrow: #2b2b2b;
$logo_bg: #6c5f89;
$secondary: #bfb2de;
$banner_bg_light: #c9b8ef;
$border: #d5d5d5;
$border_secondary: #ebebeb;
$project_background: #a897c7;
$danger: #fb7f7f;
