@import '../../constants/colors.scss';
@import '../../constants/breakpoints.scss';

.container {
  .testimonies {
    overflow-x: scroll;
    padding: 32px 40px 40px;
    display: flex;
    button {
      background-color: $transparent;
      color: $darkgrey;
      &:hover{
        background-color: $banner_bg_light;
        box-shadow: 0 0 2px 0px $banner_bg_light;
      }
      &:disabled{
        background-color: $border;
        box-shadow: none;
      }
    }
    @media #{$mob} {
      padding-left: 20px;
      padding-right: 20px;
    }
    @media #{$customTwo} {
      justify-content: center;
    }
  }
  ::-webkit-scrollbar {
    display: none;
  }
  @media #{$customTwo} {
    padding-top: 40px;
  }
}
