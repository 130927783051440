@import '../../constants/breakpoints.scss';

.container {
  padding-bottom: 40px;
  .clients {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 32px 40px 0;
    @media #{$mob} {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px;
    }
  }
  @media #{$customTwo}{
    padding-top: 40px;
  }
}
