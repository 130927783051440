@import '../../../../constants/colors.scss';
@import '../../../../mixins.scss';
@import '../../../../constants/breakpoints.scss';

.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    letter-spacing: 1.09px;
    font-size: 14px;
    cursor: pointer;
    span {
      margin-left: 16px;
      background-color: $white;
      height: 40px;
      width: 40px;
      border: 0;
      border-radius: 50%;
      outline: none;
      @include flex_center;
      img {
        width: 20px;
        height: auto;
      }
    }
    @media #{$fab} {
      margin-right: 0;
    }
  }
  @media #{$fab} {
    flex-direction: column;
    align-items: flex-end;
  }
  @media #{$mob} {
    flex-direction: column;
    align-items: flex-end;
  }
}
