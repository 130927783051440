@import "../../constants/colors.scss";
@import "../../constants/breakpoints.scss";

.container {
  width: 100%;
  height: auto;
  padding-top: 40px;
  .projects {
    padding: 32px 40px 50px;
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    display: flex;
    &::-webkit-scrollbar {
      display: none;
    }
    @media #{$mob} {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
