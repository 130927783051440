@import '../../../../constants/colors.scss';
@import '../../../../constants/breakpoints.scss';

.listItem {
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 17.5px;
  letter-spacing: 1.09px;
  cursor: pointer;
  a {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: $body_copy_primary;
    :hover {
      color: inherit;
    }
    @media #{$fab} {
      padding: 15px 20px;
    }
  }
  @media #{$fab} {
    padding: 0;
  }
}
