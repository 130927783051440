@import '../../constants/breakpoints.scss';

.heading {
  padding: 40px;
  padding-top: 0;
  padding-bottom: 0;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 0;
  @media #{$mob} {
    padding-left: 20px;
    padding-right: 20px;
  }
}
