@import "../../../constants/breakpoints.scss";

.container {
  background-image: linear-gradient(to right, #655c78 -100%, #c9b8ef 79%);
  min-height: 500px;
  max-height: 500px;
  position: relative;
  overflow: hidden;
  .infoContainer {
    position: absolute;
    bottom: 50px;
    right: 40px;
    max-width: 200px;
    z-index: 10;
    .particles {
      @media #{$mob} {
        display: none;
      }
    }
    @media #{$mob} {
      right: 20px;
    }
  }

  .imgContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-left: 55px;
      width: 600px;
      margin-top: -115px;
      z-index: 10;
      @media #{$mob} {
        margin-top: -120px;
        margin-left: -75px;
      }
      @media #{$customOne} {
        margin-top: -82px;
      }
    }
    @media #{$mob} {
      margin-top: 35px;
    }
  }
  @media #{$mob} {
    min-height: 0;
    max-height: 450px;
  }
  @media #{$customOne} {
    min-height: 0;
    max-height: 350px;
  }
}
