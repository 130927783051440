@import "../../../constants/colors.scss";
@import "../../../mixins.scss";
@import "../../../constants/breakpoints.scss";

.container {
  @include flex_center;
  width: 250px;
  height: 120px;
  background-color: $white;
  border-radius: 5px;
  padding: 10px;
  padding-left: 0;
  margin: 10px 20px 10px 0;
  img {
    width: 100%;
    height: auto;
  }
  @media #{$fab} {
    width: 170px;
    height: auto;
    background-color: transparent;
    margin: 0;
    margin-top: 20px;
    padding: 0;
  }
}
