@import '../../../constants/colors.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid $logo_bg;
  border-radius: 3px;
  padding: 3px 10px;

  .heading {
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 3px;
    font-size: 15px;
  }

  p.value {
    margin: 0;
    font-size: 14px;
  }
}
