@import '../../constants/colors.scss';

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  width: 100%;
  height: 100vh;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;

  background-color: $black_with_opacity;

  overflow: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.8);
    box-shadow: 1px 0 10px -1px black;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
}
