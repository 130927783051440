@import '../../constants/colors.scss';
@import '../../constants/breakpoints.scss';

.container {
  width: 100%;
  .summary {
    width: 350px;
    height: 100vh;
    background-color: $logo_bg;
    color: $white;
    padding: 10px 12px;
    overflow: scroll;
    position: fixed;
    top: 0;
    left: 0;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    @media #{$tab} {
      width: 100%;
    }
  }
  .messageInDetail {
    width: auto;
    max-width: 1980px;
    padding: 0 30px;
    margin-top: 70px;
    margin-left: 400px;

    @media #{$tab} {
      display: none;
    }
  }
}
