@import '../../../constants/colors.scss';

.container {
  background-color: $darkgrey2_with_opacity;
  width: 100%;
  position: relative;
  padding: 15px;
  border: 0;
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;

  .editButton {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    width: 70px;
    background-color: rgba(0, 0, 0, 0.8);
    box-shadow: 1px 0 5px -1px black;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    color: $white;
    font-size: 12px;
  }

  .sugarSection {
    margin-bottom: 12px;
  }

  .healthValuesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  .heading {
    margin-bottom: 8px;
  }

  .date {
    margin: 0;
    margin-bottom: 8px;
    color: $secondary;
    font-weight: 500;
  }
}
