@import "../../constants/colors.scss";
@import "../../mixins.scss";
.container {
  @include flex_center;
  height: 65px;
  width: 65px;
  background-color: $logo_bg;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 15px $darkgrey;
  .logo {
    font-weight: bold;
    font-size: 25px;
    color: $main_bg;
  }
}
