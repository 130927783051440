@import '../../../constants//colors.scss';

.message {
  width: 100%;
  position: relative;
  padding: 15px;
  border: 0;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $darkgrey2;
    opacity: 0.2;
    z-index: -1;
  }

  .header {
    .nameAndEmail {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    .email {
      font-size: 14px;
      font-weight: 500;
      color: $white;
      display: inline-block;
      margin: 8px 0;
      text-decoration: none;
    }
    .date {
      font-size: 10px;
      margin: 0;
      color: $secondary;
    }
  }
  .content {
    font-size: 14px;
    color: $darkgrey;
    color: $secondary;
    p {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
