@import "../../constants/colors.scss";
@import "../../mixins.scss";
@import "../../constants/breakpoints.scss";

.container {
  background-color: $white;
  padding: 40px;
  .error {
    color: $danger;
    height: 20px;
    margin: 5px 0;
  }
  .formMetaDataContainer {
    display: flex;
    justify-content: space-between;
    .formMetaData {
      width: 50%;
      display: flex;
      flex-direction: column;
      margin-right: 20px;

      @media #{$mob} {
        width: 100%;
      }
    }
    @media #{$mob} {
      flex-direction: column;
    }
  }
  .submitBtn {
    width: 100%;
    @include flex_center;
  }
  .message {
    width: 50%;
    textarea {
      width: 100%;
      padding: 13px 24px;
      border-color: $border_secondary;
      border-radius: 3px;
      height: 205px;
      max-height: 300px;
      resize: none;
      outline-color: $banner_bg_light;
      font-family: "sourceSans_regular";
      font-size: 14px;
    }
    @media #{$mob} {
      width: 100%;
    }
  }
  @media #{$mob} {
    padding-left: 20px;
    padding-right: 20px;
  }
}
