@import "../../constants/colors.scss";
@import "../../mixins.scss";

.container {
  @include flex_center;
  flex-direction: column;
  height: 110px;
  background-color: $footer_bg;
  .heading{
    color: $body_copy_secondary;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 10px;
  }
  .SocialIcons {
    @include flex_center;
  }
  a {
    text-decoration: none;
  }
}
