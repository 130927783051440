@import "../../mixins.scss";
@import "../../constants/breakpoints.scss";

.container {
  padding-bottom: 40px;
  .skills {
    @include flex-center;
    flex-wrap: wrap;
    padding: 2px 40px 0;
    @media #{$mob} {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  @media #{$customTwo} {
    padding-top: 40px;
  }
}
