@import '../../../constants/colors.scss';
@import '../../../constants/breakpoints.scss';

.heading {
  margin-bottom: 8px;
}

.sectionHeading {
  margin-bottom: 12px;
  padding-top: 10px;

  border-top: 1px solid $white;
}

.sugarSection {
  padding-bottom: 5px;

  input[name='fbs'] {
    grid-area: 'fbs';
  }
  input[name='ppbs'] {
    grid-area: 'ppbs';
  }
  input[name='bl'] {
    grid-area: 'bl';
  }
  input[name='al'] {
    grid-area: 'al';
  }
  input[name='bd'] {
    grid-area: 'bd';
  }
  input[name='ad'] {
    grid-area: 'ad';
  }

  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 5px;

  @media #{$mob} {
    display: block;
  }
}

.bloodPressureSection {
  input[name='time'] {
    grid-area: 'time';
  }
  input[name='upper'] {
    grid-area: 'upper';
  }
  input[name='lower'] {
    grid-area: 'lower';
  }
  input[name='pulse'] {
    grid-area: 'pulse';
  }

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;

  @media #{$mob} {
    display: block;
  }
}
