@import '../../../constants/colors.scss';

:global {
  .DayPicker-wrapper {
    background-color: $black_with_opacity;
  }
  .DayPicker-Day:hover{
      color: $black;
      background-color: $logo_bg !important;
  }
  .DayPicker-Day--birthday {
    background-color: $logo_bg !important;
  }
  .DayPicker-Day--today{
      background-color: $secondary;
      color: $black;
  }
}

.label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-bottom: 10px;

  input {
    margin-top: 5px;
    height: 30px;

    border: none;
    border-radius: 3px;

    color: $white;
    background: $darkgrey2_with_opacity;
    padding-left: 12px;

    outline: none;
  }
}
