@import "../../../mixins.scss";
@import "../../../constants/breakpoints.scss";

.container {
  @include flex_center;
  flex-direction: column;
  height: auto;
  width: 100px;
  margin: 30px 20px 0;
  .skillLabel {
    margin: 0;
    margin-top: 30px;
    font-weight: bold;
    text-align: center;
    @media #{$customTwo}{
      font-size: 24px;
    }
    @media #{$tab}{
      margin-top: 10px;
      font-size: 14px;
    }
  }
  .image {
    height: 80px;
    img {
      width: 100%;
      height: auto;
    }
    @media #{$customTwo}{
      height: auto;
    }
    @media #{$tab}{
      height: auto;
    }
  }
  @media #{$customTwo}{
    width: 130px;
    margin: 30px 40px 0;
  }
  @media #{$tab}{
    width: 70px;
  }
}
