@import '../../constants/colors.scss';

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: $transparent;
  border-radius: 0 0 5px 5px;
  height: 100%;
  width: 100%;
  -webkit-backdrop-filter: blur(11.6px);
  backdrop-filter: blur(6px);
  background-image: linear-gradient(#000 -131%,transparent 323%);
  z-index: -1;
}
