@import "../../constants/breakpoints.scss";
@import "../../mixins.scss";

.container {
  // background-image: linear-gradient(to right, #655c78 -100%, #c9b8ef 79%);
  margin-top: 122px;
  min-height: 500px;
  overflow: hidden;
  @media #{$fab} {
    margin-top: 0;
  }
  @media #{$fab} {
    min-height: 300px;
  }
}
.banner {
  margin-top: 99px;
  height: calc(100% - 99px);
  .largeNameContainer {
    width: 100%;
    position: absolute;
    margin: 0;
    top: 143px;
    z-index: 1;
    .largeName {
      display: flex;
      justify-content: center;
    }
    @media #{$fab} {
      top: 20px;
    }
    @media #{$mob} {
      top: 20px;
    }
  }
  @media #{$mob} {
    margin-top: 50px;
  }
}
