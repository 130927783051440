@import "../../../constants/colors.scss";
@import "../../../mixins.scss";
@import "../../../constants/breakpoints.scss";

.project {
  height: 430px;
  width: 49%;
  min-width: 542px;
  max-width: 542px;
  background-image: linear-gradient(to right, #655c78 -100%, #a68edc 79%);
  border: 0;
  border-radius: 5px;
  box-shadow: 21px 15px 47px -11px $darkgrey;
  position: relative;
  margin: 0 40px 0 0;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }
  .projectDetails {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 22%;
    overflow: hidden;
    padding: 24px;
    z-index: 1;
    color: $body_copy_secondary;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  &:hover > .projectDetails {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .viewBtnContainer {
    width: 100%;
    @include flex_center;
    .viewBtn {
      background-color: $transparent;
      color: $white;
      border: 1px solid $white;
      outline: none;
      &:hover,
      &:focus {
        background-color: $white;
        color: $body_copy_primary;
      }
    }
  }
  @media #{$fab} {
    min-width: 340px;
    max-width: 340px;
    height: 370px;
  }
  @media #{$mob} {
    margin-right: 20px;
  }
}
