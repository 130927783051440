@import "../../constants/colors.scss";

.container {
  text-transform: uppercase;
  background-color: $white;
  color: $banner_bg_light;
  margin-top: 20px;
  border: 1px solid $banner_bg_light;
  border-radius: 3px;
  font-weight: bold;
  width: 150px;
  font-size: 16px;
  padding: 15px 20px;
  outline-color: $banner_bg_light;
  transition: all 0.5s cubic-bezier(0.02, 0.26, 0.79, 0.99);
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: $banner_bg_light;
    color: $white;
  }
}
