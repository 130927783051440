@import './constants/colors.scss';
@import './constants/breakpoints.scss';

@font-face {
  font-family: 'sourceSans_regular';
  src: local('sourceSans_regular'), url(./assets//fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf);
}

body {
  margin: 0;
  font-family: sourceSans, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $body_copy_primary;
  background-color: $main_bg;
  * {
    box-sizing: border-box;
  }
}

.contain {
  width: 100%;
  max-width: 1920px;
  margin: auto;
}

h1 {
  font-size: 48px;
  margin: 0;
}
h2 {
  font-size: 40px;
  margin: 0;
}
h3 {
  font-size: 32px;
  margin: 0;
}
h4 {
  font-size: 24px;
  margin: 0;
}
h5 {
  font-size: 20px;
  margin: 0;
}
h6 {
  font-size: 16px;
  margin: 0;
}

// Carousel styles
.rec-pagination{
  button.rec-dot{
    box-shadow: 0 0 1px 3px $banner_bg_light;
  }
  button.rec-dot_active{
    box-shadow: 0 0 1px 3px $banner_bg_light;
    background-color: $banner_bg_light !important;
  }
}