@import '../../constants/colors.scss';

.container {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 100px;
  height: 40px;
  background-color: $project_background;
  border: 0;
  border-radius: 5px;
  color: $white;
  font-weight: bold;
  text-transform: capitalize;
  outline: none;
  cursor: pointer;
  transition: 0.2s all ease;
  z-index: 999;
  box-shadow: 0 0 10px -6px $black;

  &:hover {
    background-color: $logo_bg;
  }
}
