@import '../../constants/colors.scss';

.container {
  background-color: $logo_bg;
  position: relative;
  padding: 15px;
  border: 0;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 500px;
  max-width: 100%;
  max-height: 100%;

  overflow: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
