.container {
  height: 30px;
  width: 30px;
  margin: 0 8px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }
}
