@import "../../constants/colors.scss";
@import "../../constants/breakpoints.scss";
@import "../../mixins.scss";
.container {
  min-width: 280px;
  max-width: 100%;
  // min-height: 347px;
  padding: 40px;
  border-radius: 5px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  letter-spacing: 0.2px;
  &:not(:last-child) {
    margin-right: 30px;
  }
  .profileDetails {
    text-align: center;
    font-size: 16px;
    .name {
      font-size: 40px;
      font-weight: bold;
      margin-top: 0;
      margin-bottom: 10px;

      @media #{$mob}{
        font-size: 24px;
      }
    }
    .company{
      color: $darkgrey3;
      margin-top: 0;
    }
  }
  .testimony {
    margin-top: 20px;
    line-height: 1.4;
  }

  @media #{$mob}{
    padding: 30px 20px;
  }
}
