@import "../../../constants/breakpoints.scss";

.largeName {
  font-size: 126px;
  font-weight: bold;
  letter-spacing: 9.83px;
  margin: 0;
  @media #{$mob} {
    font-size: 50px;
  }
}
