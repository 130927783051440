@import "../../constants/colors.scss";

.input {
  height: 48px;
  border: 1px solid $border_secondary;
  border-radius: 3px;
  padding: 13px 24px;
  font-size: 14px;
  outline-color: $banner_bg_light;
}
