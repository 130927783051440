@import "../../../constants/colors.scss";
@import "../../../mixins.scss";
@import "../../../constants/breakpoints.scss";

.hamburger {
  height: 40px;
  width: 40px;
  position: absolute;
  top: 30px;
  right: 40px;
  padding: 5px;
  display: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
  background-color: $main_bg;
  border-radius: 10px;
  box-shadow: 3px 5px 30px -10px $black;
  .hamburgerStick {
    margin: 2px;
    height: 4px;
    border-radius: 10px;
    width: 100%;
    background-color: $body_copy_primary;
    &.stickTop {
      width: 20px;
    }
    &.stickMiddle {
      width: 25px;
    }
    &.stickBottom {
      width: 15px;
    }
  }
  @media #{$fab} {
    display: flex;
  }
  @media #{$mob} {
    right: 20px;
  }
}

.customOverlay {
  display: none;
  position: fixed;
  z-index: 11;
  background-image: none;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  @media #{$fab} {
    display: block;
  }
}

.container {
  height: 100px;
  background: $main_bg;
  padding: 0 40px;
  z-index: 1000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  box-shadow: 0px 7px 30px -31px $darkgrey;

  .customPositionedLogo {
    display: none;
    @media #{$fab} {
      display: flex;
      position: absolute;
      top: 20px;
      left: 40%;
    }
  }
  .list {
    list-style: none;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    @include flex_center;
    .logo {
      @media #{$fab} {
        display: none;
      }
    }
    @media #{$fab} {
      background-color: $main_bg;
      width: 300px;
      display: block;
      padding-top: 100px;
      transform: translateX(300%);
      box-shadow: 0px 0px 23px -8px $black;
      transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    &.slideIn {
      transform: translateX(-300px);
    }
  }
  @media #{$fab} {
    left: auto;
    right: 0;
    width: 0;
    height: 100%;
    padding: 0;
    box-shadow: none;
    background: $transparent;
  }
  @media #{$mob} {
    left: 100%;
    right: auto;
  }
}
// }
