@import '../../constants/colors.scss';

.container {
  background-color: $logo_bg;
  color: $white;

  min-height: 100vh;
  width: 100%;

  padding: 10px;

  .addButton {
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    color: $white;
    background-color: $footer_bg;

    position: fixed;
    bottom: 20px;
    right: 20px;
    padding-top: 4px;

    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100%;

    font-size: 20px;
    font-weight: 500;
  }
}
