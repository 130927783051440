@import '../../../constants/colors.scss';

.container {
  padding: 20px 12px;

  p {
    margin: 0;
  }

  .header {
    .nameAndEmail {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .email {
      font-weight: bold;
      text-decoration: none;
    }

    .name {
      font-weight: 400;
    }

    .email,
    .date {
      font-size: 14px;
      color: $darkgrey;
    }
  }
  .message {
    margin-top: 20px;
    letter-spacing: 0.5px;
    line-height: 1.4;
  }
}
